.hero-container {
  position: relative;
  background-image: url("prev_home.jpg");
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
