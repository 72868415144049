.navbar-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10; /* Ensure navbar is above the hero image */
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
    color: white;
    
  }
  
  /* If you want it fully transparent */
  .navbar-container {
    background-color: transparent;
  }
  